import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Modal,
  Heading,
  LayoutGrid,
  ChevronAndTitle,
  EmptyListCTA,
  CaseStepsProgress,
  Button,
} from '@rabbit/elements/shared-components';
import ROUTE_NAME from '../../../utils/url-constants';
import HoldingDetailsActionsSection from '../../../components/molecules/HoldingDetailsActionsSection/HoldingDetailsActionsSection';
import {
  useConsumerHoldingEditor,
  useConsumerQuestionnnaire,
  useDeleteConsumerHolding,
  useGetConsumerHoldings,
} from '@rabbit/bizproc/react';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import noProductsImg from '../../../assets/images/no_products_yet.svg';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { MissingHoldingInfoSection } from 'apps/olive/src/components/organisms/MissingHoldingInfoSection/MissingHoldingInfoSection';
import catOnABike from '../../../assets/images/cat-on-a-bike.png';
import imgHippo from '../../../assets/images/hippo.png';
import { getStoneImages } from '@rabbit/olive/utils/helpers';
import { useTranslation } from 'react-i18next';
import CarHoldingDetailsSection from '@rabbit/olive/components/molecules/CarHoldingDetailsSection/CarHoldingDetailsSection';
import {
  DTService,
  PersonaTypeSingleLetter,
  TableCover,
} from '@rabbit/data/types';
import {
  BL_Service,
  BL_Warranty,
  CaseflowCaseTypes,
} from '@rabbit/bizproc/core';
import { AppContext } from '@rabbit/app-context';
import HoldingDetailsSection from 'libs/elements/shared-components/src/lib/organisms/HoldingDetailsSection/HoldingDetailsSection';
import WarrantyViewer from 'libs/elements/shared-components/src/lib/molecules/WarrantyViewer/WarrantyViewer';
import ServiceViewer from 'libs/elements/shared-components/src/lib/molecules/ServiceViewer';
import ModalAddNewService from '@rabbit/olive/components/organisms/ModalAddNewService';

type TenantType = 'carTenants' | 'tableTenants' | 'defaultTenants';

function HoldingSingleDetailsView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const holdingId = location.pathname.split('/')[2];
  const deleteHolding = useDeleteConsumerHolding(holdingId);
  const [searchParams, setSearchParams] = useSearchParams();
  const { personaId } = useMyConsumerPersona();
  const { getSingleHolding } = useGetConsumerHoldings(personaId || '');
  const { getAllHoldingProxies } = useGetConsumerHoldings(personaId || '');
  const { getSingleQuestionnaire } = useConsumerQuestionnnaire();
  const [openModal, setOpenModal] = useState(false);
  const [openHintModal, setOpenHintModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false);
  const [loadingQuestionnaireModal, setLoadingQuestionnaireModal] =
    useState(false);
  const [holdingSummary, setHoldingSummary] =
    useState<ConsumerHoldingSummaryShape>({} as ConsumerHoldingSummaryShape);
  const { config, tenantInfo } = useContext(AppContext);

  const [showRoadsideAssistancePlan, setShowRoadsideAssistancePlan] =
    useState<boolean>(false);
  const [roadSideAssistancePayload, setShowRoadsideAssistancePayload] =
    useState<DTService | undefined>();

  const { body, isReady, update, commit } = useConsumerHoldingEditor(
    personaId ?? '',
    holdingId
  );

  const {
    self_registration,
    active_cases,
    show_missing_info_cta,
    purchase_time,
    warranty_term,
    purchase_country,
  } = holdingSummary;

  const purchaseCountryRequired =
    config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.SHELTA;

  const isSelfRegistered = self_registration;
  const tenantLink = t('tenantLink');
  let holdingHasBasicInfo =
    t('tenantLink') === 'WARRANTYIRELAND' ||
    t('tenantLink') === 'PINNACLEWARRANTIES' ||
    t('tenantLink') === 'NUCOVER' ||
    (!!purchase_time &&
      purchase_time >= 0 &&
      !!warranty_term?.amount &&
      !!(purchaseCountryRequired ? purchase_country : true));

  if (holdingSummary.brand === 'NUCOVER') {
    holdingHasBasicInfo = true;
  }
  const warranty = BL_Warranty.getLatestWarranty(holdingSummary.warranties);
  if (warranty) {
    const warrantor = warranty.warrantorLink;
    if (
      warrantor === `${PersonaTypeSingleLetter.Warrantor}:PINNACLEWARRANTIES` ||
      warrantor === `${PersonaTypeSingleLetter.Warrantor}:NUCOVER` ||
      warrantor === `${PersonaTypeSingleLetter.Warrantor}:WARRANTYIRELAND`
    ) {
      holdingHasBasicInfo = true;
    }
  }

  let errorTimeout: any;
  useEffect(() => {
    if (!body?.private) return;
    const checkSerialNumber = () => {
      if (
        config.HOLDINGS.REGISTRATION.SERIAL_NUMBER_REQUIRED &&
        !body?.private?.serial
      ) {
        clearTimeout(errorTimeout);
        errorTimeout = setTimeout(
          () => toast.error(t('general.serialNumberRequired')),
          500
        );
        navigate('/');
        return null;
      }
    };
    checkSerialNumber();
  }, [body?.private]);

  useEffect(() => {
    if (!personaId) return;
    const checkHolding = async () => {
      const holdings = await getAllHoldingProxies();
      if (!(holdings instanceof Error)) {
        const ids = holdings.map((i) => i.docid.split('_')[0]);
        const holding = holdingId.split('_')[0];
        if (ids.indexOf(holding) === -1) {
          navigate('/');
        }
      }
    };

    checkHolding();
  }, [personaId]);

  const onSkipQuestionnaire = async () => {
    if (!body || !isReady || !body?.manufacturer) return;
    setLoadingQuestionnaireModal(true);
    try {
      update({
        ...body,
        manufacturer: {
          ...body.manufacturer,
          questionnaire: {
            skip: 'skipped',
          },
        },
      });

      await commit();

      setShowQuestionnaireModal(false);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingQuestionnaireModal(false);
    }
  };

  const checkQuestionnaire = () => {
    getSingleQuestionnaire(holdingId)
      .then((res) => {
        if (
          !res.questionnaire ||
          (res.questionnaire?.product_main_use === '' &&
            res.questionnaire?.product_to_be_used === '' &&
            res.questionnaire?.property_shade === '' &&
            res.questionnaire.purchase_inspiration === '' &&
            res.questionnaire.purchase_reason === '' &&
            res.questionnaire.purchase_satisfaction === '' &&
            res.questionnaire.shelta_aware === '')
        ) {
          setShowQuestionnaireModal(true);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  if (error)
    return (
      <div className="mx-auto w-full sm:w-2/3 md:w-1/2 lg:w-1/3">
        <EmptyListCTA
          copy={
            'Something went wrong. Please try again later or contact our support team.'
          }
          imgSrc={noProductsImg}
          imgAlt={'Sad face on box'}
          buttonLabel={'Add product'}
          buttonAction={() => navigate(ROUTE_NAME.PRODUCTS_SEARCH)}
        />
      </div>
    );

  const handleOpenDeleteProductModal = () => {
    setOpenModal(!openModal);
  };

  const handleHintModal = () => {
    setOpenHintModal(!openHintModal);
  };

  const modalSettings = {
    text: 'You are about to delete this product. You will lose all information related to this product. Are you sure you want to continue?',
    primaryButtonText: 'Yes',
    outlineButtonText: 'No, cancel',
    handlePrimaryClick: async () => {
      try {
        const res = await deleteHolding();
        if (res === true) {
          toast.success('Product deleted successfully.');
          navigate(ROUTE_NAME.PRODUCTS);
        }
      } catch (err) {
        toast.error("Couldn't delete product, please try again later.");
        console.log(err);
      } finally {
        setOpenModal(false);
      }
    },
    handleOutlineClick: () => setOpenModal(false),
  };

  const hintModalSettings = {
    text: t(
      'To access this feature you need to complete the product’s information.'
    ),
    primaryButtonText: t('Edit product information'),
    title: t('Missing product information'),
    handlePrimaryClick: () => {
      setOpenHintModal(false);
      navigate(`${ROUTE_NAME.PRODUCTS}/${holdingId}/edit-srv`);
    },
    handleClose: () => setOpenHintModal(false),
  };

  const questionnaireModalSettings = {
    handleClose: () => setShowQuestionnaireModal(false),
    title: 'Product improvement survey',
  };

  useEffect(() => {
    (async () => {
      const holding = await getSingleHolding(holdingId);
      if (holding instanceof Error) setError(holding);
      else {
        setHoldingSummary(holding);
        {
          /* Keep the Shelta string hardcoded for exceptions - VP */
        }
        if (holding.brand === 'Shelta Australia' || holding.brand === 'Shelta')
          checkQuestionnaire();

        if (holding.services) {
          setShowRoadsideAssistancePayload(holding.services);
        }
      }
    })();

    if (searchParams.get('new-add-success') != null) {
      setSuccessModal(true);
      searchParams.delete('new-add-success');
      setSearchParams(searchParams);
    }
  }, []);

  let tenantType: TenantType = 'defaultTenants';
  if (holdingSummary?.srvInfo?.type === 'vehicle') tenantType = 'carTenants';
  if ((holdingSummary?.srvInfo?.productInfo as TableCover)?.typeofStone)
    tenantType = 'tableTenants';

  const productImage = holdingSummary?.srvInfo?.productInfo
    ? getStoneImages(
        (holdingSummary?.srvInfo?.productInfo as TableCover).typeofStone
      )
    : undefined;

  const fetchRoadSideAssistanceOffer = (services?: DTService[] | null) => {
    if (!services?.length) {
      setShowRoadsideAssistancePayload(undefined);
      return;
    }
    const latestService = BL_Service.getLatestService(services);
    setShowRoadsideAssistancePayload(latestService as any);
  };

  return (
    <>
      <ChevronAndTitle
        title={'Product information'}
        onClick={() => navigate(ROUTE_NAME.PRODUCTS)}
      />
      <div className="flex flex-col gap-8">
        {tenantType === 'defaultTenants' && (
          <div className="flex flex-col gap-8">
            <HoldingDetailsSection
              data={holdingSummary}
              tenantInfo={tenantInfo}
            />
          </div>
        )}
        {tenantType === 'tableTenants' && (
          <div className="flex flex-col gap-8">
            <HoldingDetailsSection
              data={holdingSummary}
              tenantInfo={tenantInfo}
              image={productImage}
            />
          </div>
        )}
        {tenantType === 'carTenants' && (
          <div className="flex flex-col gap-8">
            {holdingSummary && holdingSummary?.holdingId && (
              <CarHoldingDetailsSection holding={holdingSummary} />
            )}
          </div>
        )}
        {holdingSummary && holdingSummary?.holdingId && (
          <WarrantyViewer
            tenantInfo={tenantInfo}
            warranties={holdingSummary.warranties || []}
            tenantType={tenantType}
          />
        )}

        {/* TODO : Add Road Side Assistance Service */}
        {roadSideAssistancePayload && tenantLink === 'PINNACLEWARRANTIES' && (
          <ServiceViewer service={roadSideAssistancePayload} />
        )}
      </div>
      {active_cases && active_cases.length > 0 ? (
        <>
          <div className="mt-8 mb-4">
            <Heading kind={'h3'}>Claim status</Heading>
          </div>
          <div
            onClick={() =>
              holdingSummary &&
              active_cases?.[0] &&
              navigate(`${ROUTE_NAME.REPAIRS}/${active_cases[0]}`)
            }
            className="cursor-pointer"
          >
            {active_cases &&
              active_cases.map((caseId, index) => (
                <div
                  key={index}
                  className="rounded rounded-[6px] bg-white px-10 py-10"
                >
                  <CaseStepsProgress
                    personaId={personaId}
                    currentCaseId={caseId}
                  />
                </div>
              ))}
          </div>
        </>
      ) : (
        ''
      )}
      {holdingSummary && holdingSummary?.holdingId && !holdingHasBasicInfo && (
        <div className={isSelfRegistered ? 'mt-8 mb-4' : 'mt-4'}>
          <MissingHoldingInfoSection
            id={holdingId}
            showCTA={isSelfRegistered && show_missing_info_cta === true}
          />
        </div>
      )}
      <div className="mt-8 mb-4">
        {holdingId && holdingSummary && holdingSummary?.holdingId ? (
          <HoldingDetailsActionsSection
            holdingId={holdingId}
            deleteFn={handleOpenDeleteProductModal}
            hintFn={handleHintModal}
            data={holdingSummary}
            personaId={personaId}
            holdingHasBasicInfo={holdingHasBasicInfo}
            setShowRoadsideAssistancePlan={setShowRoadsideAssistancePlan}
          />
        ) : null}
      </div>
      {/* TODO - VP: This is a temporary solution to show the product suggestions only for Shelta Australia. */}
      {tenantInfo?.name === 'Shelta Australia' && (
        <>
          <div className="mt-8 mb-4">
            <Heading kind={'h3'}>Suggestions</Heading>
          </div>
          <LayoutGrid kind={'productSuggestions'} />
        </>
      )}
      {openModal && <Modal kind={'pop-up'} settings={modalSettings} />}
      {openHintModal && <Modal kind={'info'} settings={hintModalSettings} />}
      {showQuestionnaireModal && (
        <Modal kind={'generic'} settings={questionnaireModalSettings}>
          <div>
            <div className="flex flex-col items-center gap-6 px-4 py-6">
              <img src={imgHippo} alt="hippo" className="w-[116px]" />
              <div className="text-center text-gray-500">
                Hello there! {tenantInfo?.name} would like to ask you some
                questions about your product. This will help {tenantInfo?.name}{' '}
                make better products and better serve it’s customers. It will
                only take 2 minutes...
              </div>
            </div>
            <div className="flex flex-col items-center gap-3 border-t border-gray-200 px-4 pt-6 pb-3">
              <Button
                kind="primary"
                className="w-full"
                disabled={loadingQuestionnaireModal}
                onClick={() =>
                  navigate(`${ROUTE_NAME.PRODUCTS}/${holdingId}/questionnaire`)
                }
              >
                Ok, I have got 2 minutes
              </Button>
              <Button
                kind="outline"
                className="w-full"
                disabled={loadingQuestionnaireModal}
                onClick={() => onSkipQuestionnaire()}
              >
                Maybe another time
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {successModal && (
        <Modal
          kind="generic"
          settings={{ handleClose: () => setSuccessModal(false) }}
          className="m-auto w-[448px] rounded-md border bg-white"
        >
          <div className="flex flex-col items-center gap-6 px-4 pt-16 pb-10">
            <Heading kind="h2">Congratulations 🎉</Heading>
            <img src={catOnABike} alt="cat on a bike" className="w-[192px]" />
            <Heading kind="p">You just added your first product! 🚀</Heading>
            <Button
              kind="primary"
              className="w-full"
              onClick={() => setSuccessModal(false)}
            >
              Thank you!
            </Button>
          </div>
        </Modal>
      )}

      {showRoadsideAssistancePlan && (
        <ModalAddNewService
          holdingId={holdingSummary.holdingLink}
          consumerLink={holdingSummary.consumerLink}
          tenantLink={tenantLink}
          handleClose={() => setShowRoadsideAssistancePlan(false)}
          shouldRefresh={fetchRoadSideAssistanceOffer}
        />
      )}
    </>
  );
}

export default HoldingSingleDetailsView;
