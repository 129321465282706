import {
  DTConsumer_Private,
  DTHolding_Public,
  FBD_Consumer_Private,
  FBD_Holding_Public,
  FBD_Holding_Private,
  FBD_Vendable,
  FBD_Retailer_Public,
  DTRetailer_Public,
  FBD_HoldingProxy,
  SELF_REGISTERED_VENDABLE,
  FBD_Category_Public,
} from '@rabbit/data/types';
import { ConsumerHoldingSummaryShape } from './types';
import { add, fromUnixTime, getUnixTime, sub } from 'date-fns';
import { BL_Service, BL_Warranty, IsValidLinkToDocument } from '@rabbit/bizproc/core';

/** Links the holding to the given consumer.
 *
 * This is designed to be used as part of a wider holding creation process. Therefore some care must be taken:
 * * The holding must have its expected docid set
 * * The caller must write the holding to the database
 *
 * * This function will update the consumer and takes care of writing the consumer to the database. The written result is not returned, but the argument passed in is modified. You should probably just refresh it if you need to use it again.
 */
export async function Data_Holding_AttachToConsumer_SaveConsumer(
  holding: DTHolding_Public,
  consumer: DTConsumer_Private,
  isSRV?: boolean
) {
  if (!consumer.holdings) {
    consumer.holdings = [];
  }
  consumer.holdings.push({ holdingLink: holding.docid, mfr: holding.mfr });

  if (isSRV) {
    if (!consumer.srv_holdings) {
      consumer.srv_holdings = [];
    }
    consumer.srv_holdings.push({
      holdingLink: holding.docid,
      mfr: holding.mfr,
    });
  }

  await FBD_Consumer_Private.set(consumer);

  holding.consumer = consumer.docid;
  return holding;
}

/** Retrieve a summary of a holding for the consumer.
 *
 * This will be further developed as we go, and is currently setting some values to dummy data so there's enough information to use on the FE.
 * The shape of this summary is listed in the ConsumerHoldingSummaryShape interface - also a wip.
 * @param holdingDocid The docid of the holding to summarise
 * @returns A summary of the holding
 * @throws If the holding is not found
 * @throws If the vendable is not found
 *
 */
export async function Holding_SummariseForConsumer(holdingDocid: string) {
  const holding_private = await FBD_Holding_Private.get(holdingDocid);
  const holdingProxyResult = await FBD_HoldingProxy.query()
    .where('holdingLink', '==', holdingDocid)
    .where('mfr', '==', holding_private?.mfr)
    .where('consumer', '==', holding_private?.consumer)
    .limit(1)
    .getContents();

  if (!holdingProxyResult || holdingProxyResult.length !== 1)
    throw new Error(`Holding ${holdingDocid} not found`);
  let holdingDataSummary: ConsumerHoldingSummaryShape =
    {} as ConsumerHoldingSummaryShape;

  const holding_proxy = holdingProxyResult[0];

  holdingDataSummary.holdingId = holdingDocid;

  const {
    purchase_time,
    serial,
    active_cases,
    closed_cases,
    deleted,
    self_registration,
    serial_proof,
    installation,
    installer,
    installation_attachments,
    internal_comment,
    services
  } = holding_proxy;

  const {
    purchase_location,
    purchase_location_other,
    purchase_country,
    warranties,
  } = holding_proxy || {};

  if (!warranties) {
    // BNC: Compiler complained that warranties may be undefined but Diogo said it was cool...for now.
    throw new Error('No warranty found');
  }

  holdingDataSummary = {
    ...holdingDataSummary,
    purchase_time,
    serial,
    installation: holding_private?.installation!,
    active_cases,
    closed_cases,
    deleted,
    purchase_location_other,
    serial_proof,
    internal_comment,
    installer,
    installation_attachments,
    holdingLink:holding_proxy.docid,
    consumerLink:holding_proxy.consumer
  };

  // Get full purchase_location data
  let full_purchase_location: DTRetailer_Public | null = null;

  if (purchase_location)
    full_purchase_location = await FBD_Retailer_Public.get(purchase_location);

  const { purchase_price } = holding_proxy || {};

  const receipt = holding_private?.receipt
    ? JSON.parse(JSON.stringify(holding_private?.receipt))
    : undefined;

  const warranty = BL_Warranty.getLatestWarranty(warranties);

  const calcWarrantyExpiryDate = () => {
    if (warranty?.endDate) return warranty.endDate;
    const expiry_date =
      purchase_time && warranty?.duration
        ? getUnixTime(
            sub(
              add(fromUnixTime(purchase_time), {
                [warranty.duration.division]: warranty.duration.amount,
              }),
              {
                days: 1,
              }
            )
          )
        : undefined;
    return expiry_date;
  };

  holdingDataSummary = {
    ...holdingDataSummary,
    purchase_price: purchase_price ?? '-',
    warranties: holding_proxy?.warranties ?? [],
    full_purchase_location: full_purchase_location ?? null,
    purchase_country: purchase_country ?? '',
    purchase_time: purchase_time ?? 0,
    warranty_term: warranty?.duration ?? { division: 'days', amount: 0 },
    warranty_expiry_date: calcWarrantyExpiryDate() ?? -1, // -1 = lifetime warranty
    purchase_proof: receipt,
    shopifyLinks: holding_proxy.shopifyLinks ?? null,
    serial_proof: serial_proof ?? [],
  };

  if (
    holding_proxy.vendable &&
    holding_proxy.vendable !== SELF_REGISTERED_VENDABLE
  ) {
    if (IsValidLinkToDocument(holding_proxy.vendable)) {
      const vendable = await FBD_Vendable.get(holding_proxy.vendable);
      if (!vendable) {
        throw new Error(`Couldn't find vendable: ${holding_proxy.vendable}`);
      }

      if (vendable.img && vendable.img.length > 0) {
        holdingDataSummary.img = vendable.img[0];
      }

      const {
        full,
        title,
        brand,
        upc,
        asin,
        mpn,
        ampn,
        manual,
        category,
        series,
      } = vendable;

      // const brand = 'Shelta Australia'; // todo remove hardcode when no longer necessary
      const vCategory =
        vendable.freeform?.customerCategory ??
        (category?.length ? category[category.length - 1] : '-');

      const categoryPublicData = await FBD_Category_Public.query()
        .where('key', '==', vCategory)
        .limit(1)
        .getContents();

      const categoryTitle = categoryPublicData[0]?.titles?.en;

      holdingDataSummary = {
        ...holdingDataSummary,
        vendableId: holding_proxy.vendable,
        full,
        title,
        brand: brand ?? '-',
        upc,
        asin,
        ampn,
        manual,
        series,
        mpn,
        category: categoryTitle,
      };
    }
  }

  // Owner's images and title will override the manufacturer's image
  holdingDataSummary.self_registration = false;

  if (self_registration) {
    holdingDataSummary.self_registration = true;
    if (self_registration.img && self_registration.img.length > 0) {
      holdingDataSummary.img = self_registration.img[0];
    }
    holdingDataSummary.title = self_registration.title;
    holdingDataSummary.brand = self_registration.brand;
    holdingDataSummary.category = self_registration.category_title;
    holdingDataSummary.show_missing_info_cta =
      self_registration.show_missing_info_cta;
    if (self_registration.srvInfo) {
      holdingDataSummary.srvInfo = self_registration.srvInfo;
    }
  }

  if(services && services.length>0){
    const latestService = BL_Service.getLatestService(services);
    holdingDataSummary.services = latestService;
    holdingDataSummary.docid = holding_proxy.docid;
  }

  return holdingDataSummary;
}

// TODO: @DEV-754: This should all be handled by Holding_Proxy. Remove once safe
export async function Holding_SummariseAllForConsumerPersona(
  persona: DTConsumer_Private
) {
  const summaries = [];

  for (const holding of persona.holdings || []) {
    const summary = await Holding_SummariseForConsumer(holding['holdingLink']);
    summaries.push(summary);
  }
  return summaries;
}

/** Retrieve the name of a holding for entry into caseflow
 */
export async function Holding_GetNameForCase(docid: string) {
  let title = '';

  const holding_public = await FBD_Holding_Public.get(docid);
  if (!holding_public) throw new Error('Holding not found');

  if (holding_public.self_registration) {
    title = holding_public.self_registration.title;
  }

  if (IsValidLinkToDocument(holding_public.vendable)) {
    const vendable = await FBD_Vendable.get(holding_public.vendable || '');
    if (vendable) {
      title = vendable.full || vendable.title;
    }
  }

  return title;
}
