import classNames from 'classnames';
// import MaterialReactTable, {
//   MaterialReactTableProps,
// } from 'material-react-table';

import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_Row,
  MRT_ToolbarInternalButtons,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Button, IconButton, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useState } from 'react';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { Download, PrintOutlined } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import { MenuProps } from '@mui/material/Menu';
import { formatAddressPlain } from '@rabbit/sage/utils/helpers';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { useMemo } from 'react';
import React from 'react';

export interface TableProps {
  useCallbackClickRow?: any;
  inner?: boolean;
  name: string;
  columns: any;
  data: Record<string, any>[];
  onSelectRow?: any;
  initialState?: object;
  enableBottomToolbar?: boolean;
  enableTopToolbar?: boolean;
  enableColumnActions?: boolean;
  enableColumnFilters?: boolean;
  enableSorting?: boolean;
  enableExpanding?: boolean;
  enableExpandAll?: boolean;
  columnFilterDisplayMode: string;
  enableFacetedValues?: boolean;
  enableRowSelection?: boolean;
  enableToolbarInternalActions?: boolean;
  positionToolbarAlertBanner?: string;
  advancedTable?: boolean;
  // manualPagination?: boolean;
  // manualSorting?: boolean;
}

export function Table(props: any) {
  const {
    useCallbackClickRow,
    enableBottomToolbar = true,
    enableTopToolbar = true,
    enableColumnFilters = false,
    enableColumnActions = false,
    enableSorting = true,
    inner = false,
    onSelectRow,
    enableExpanding,
    enableExpandAll,
    enableFacetedValues = false,
    enableToolbarInternalActions = false,
    positionToolbarAlertBanner = 'bottom',
    columnFilterDisplayMode = 'custom',
    name = 'material-table',
    advancedTable = false,
  } = props;
  const currentPage = localStorage.getItem(`table-${name}-page-index`) || 0;
  const [firstLaunch, setFirstLaunch] = React.useState(true);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [columnFilterOpen, setColumnFilterOpen] = useState(true);

  const table = useMaterialReactTable({
    data: props.data,
    columns: props.columns,
    initialState: props.initialState,
    columnFilterDisplayMode: columnFilterDisplayMode,
    enableBottomToolbar: enableBottomToolbar,
    positionToolbarAlertBanner: positionToolbarAlertBanner,
    enableColumnActions: enableColumnActions,
    enableColumnFilters: enableColumnFilters,
    enableSorting: enableSorting,
    enablePinning: true,
    enableTopToolbar: enableTopToolbar,
    enableExpanding: enableExpanding,
    enableExpandAll: enableExpandAll,
    muiPaginationProps(props) {
      const page = props.table.getState().pagination.pageIndex;
      if (!firstLaunch) {
        localStorage.setItem(`table-${name}-page-index`, String(page));
      }
      if (firstLaunch) {
        props.table.setPageIndex(() => Number(currentPage));
      }
      setFirstLaunch(false);
    },
    defaultColumn: {
      minSize: 5,
      maxSize: 9001, //allow columns to get larger than default
      //size: 260, //make columns wider by default
    },
    enableToolbarInternalActions: enableToolbarInternalActions,
    positionGlobalFilter: 'left',
    enableFacetedValues: enableFacetedValues,
    muiBottomToolbarProps: {
      className: classNames({ '!hidden': props.enablePagination === false }),
    },
    muiTopToolbarProps: ({ table }) => ({
      className:
        '!bg-gray-100 !border-gray-200 border-b-[1px] !min-h-[82px] !p-5',
    }),
    muiTablePaperProps: ({ table }) => ({
      style: {
        zIndex: table.getState().isFullScreen ? 10000 : undefined,
        top: table.getState().isFullScreen ? '143px' : undefined,
      },
      elevation: 0,
      className: classNames(
        '!rounded-lg !border-[1px] !border-gray-200 !overflow-hidden',
        { '!rounded-none !border-none': inner }
      ),
    }),
    muiTableHeadCellProps: {
      className:
        '!font-nunito !bg-gray-50 !text-sm !uppercase !text-gray-700 !py-2 !pl-2 !pr-0 !align-middle',
      sx: {
        '.Mui-TableHeadCell-Content-Wrapper': { minWidth: '0px' },
      },
    },
    muiTableBodyRowProps: (data: any) => ({
      onClick: (event: any) => {
        //preventing row click if there other custom component inside cell
        if (
          String(event?.target?.className)?.includes('MuiTableCell-root') &&
          (useCallbackClickRow || onSelectRow)
        ) {
          useCallbackClickRow && useCallbackClickRow(data.row?.original);
          onSelectRow && onSelectRow(data.row?.index);
        }
      },
      className: classNames(
        'relative z-0 hover:z-10 !font-nunito !text-base !text-gray-900 !overflow-visible !pl-2 !pr-0 hover:!outline-primary-900',
        { '!cursor-pointer': useCallbackClickRow || onSelectRow }
      ),
    }),
    muiTableBodyCellProps: (data: any) => ({
      onClick: (event: any) => {
        //preventing row click if there other custom component inside cell
        if (
          String(event?.target?.className)?.includes('MuiTableCell-root') &&
          (useCallbackClickRow || onSelectRow)
        ) {
          useCallbackClickRow && useCallbackClickRow(data.row?.original);
          onSelectRow && onSelectRow(data.row?.index);
        }
      },
      className: classNames(
        '!font-nunito !text-base !text-gray-900 !overflow-visible !pl-2 !pr-0 hover:!outline-primary-900',
        { '!cursor-pointer': useCallbackClickRow || onSelectRow }
      ),
    }),
    ...props,
    muiSearchTextFieldProps: {
      placeholder: 'Search',
      variant: 'outlined',
      margin: 'none',
      ...(!advancedTable && {
        className: '!w-[600px] !my-3 bg-white',
        sx: {
          '> .MuiOutlinedInput-root': {
            '& > fieldset': { borderRadius: '10px', backgroundColor: '#fff' },
            '& > input': { padding: '12px 0', backgroundColor: '#fff' },
            '& > svg': { width: '18px', height: '18px' },
          },
        },
      }),
      ...(advancedTable && {
        className: '!w-[500px] bg-white',
        sx: {
          '> .MuiOutlinedInput-root': {
            '& > fieldset': { borderRadius: '10px' },
            '& > input': {
              borderRadius: '10px',
              padding: '10px 0',
              backgroundColor: '#fff',
            },
            '& > svg': { width: '18px', height: '18px' },
          },
        },
      }),
      ...(props.muiSearchTextFieldProps || {}),
    },

    muiFilterTextFieldProps: ({ column }) => ({
      variant: 'outlined',
      margin: 'none',
      className: 'bg-white font-nunito',
      sx: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': { borderRadius: '5px', padding: '0' },
          '& input': {
            width: '100%',
            borderRadius: '5px',
            padding: '8px 5px',
            fontSize: '12px',
          },
          '& .MuiSelect-select': {
            borderRadius: '5px',
            padding: '8px 5px',
            fontSize: '12px',
          },
        },
        '& .MuiPaper-root': {
          padding: 0,
          borderRadius: 6,
          minWidth: 180,
          fontSize: 12,
          color: 'rgb(55, 65, 81)',
          '& .MuiMenu-list': {
            padding: '0',
            fontSize: 12,
          },
          '& .MuiMenuItem-root': {
            fontSize: 12,
            padding: 0,
            '& .MuiSvgIcon-root': {
              fontSize: 12,
            },
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
      },
    }),
  });

  const globalTheme = useTheme(); //(optional) if you already have a theme defined in your app root, you can import here

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: 'rgb(59 125 112)',
          },
        },
        typography: {
          fontFamily: [
            'Nunito',
            'ui-sans-serif',
            'system-ui',
            '-apple-system',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
          ].join(','),
        },
      }),
    [globalTheme]
  );

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: `data-export-${new Date().toISOString()}`,
  });
  const processDataForExport = (data: any[]) =>
    data.map(
      ({
        link,
        timeStamp,
        purchaseDate,
        expiring,
        registered,
        status,
        start,
        expires,
        id,
        in_warranty,
        edit,
        spotlight,
        date_modified,
        local,
        cloudsaved,
        docid,
        actions,
        error,
        version,
        lastTouched,
        shippingAddress,
        ...rest
      }: any) => ({
        ...rest,
        ...(purchaseDate && {
          'Purchase Date': new Date(purchaseDate).toLocaleDateString(),
        }),
        ...(expiring && { Expiring: new Date(expiring).toLocaleDateString() }),
        ...(registered && {
          Registered: new Date(registered).toLocaleDateString(),
        }),
        ...(start && { Start: new Date(start).toLocaleDateString() }),
        ...(expires && { Expires: new Date(expires).toLocaleDateString() }),
        ...(date_modified && {
          'Date Modified': new Date(date_modified).toLocaleDateString(),
        }),
        ...(lastTouched && {
          'Last Touched': new Date(lastTouched).toLocaleDateString(),
        }),
        ...(status && { Status: status?.props?.children ?? status }),
        ...(in_warranty && {
          'In Warranty': in_warranty.props.children,
        }),
        ...(shippingAddress && {
          'Shipping Address': formatAddressPlain(shippingAddress[0]),
        }),
      })
    );

  const handleExportRows = (rows: MRT_Row<any>[]) => {
    const dataImport = processDataForExport(rows.map((row) => row.original));
    handleClose();
    const csv = generateCsv(csvConfig)(dataImport);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    console.log('handleExportData', props.data);
    const dataImport = processDataForExport(props.data);
    handleClose();
    const csv = generateCsv(csvConfig)(dataImport);
    download(csvConfig)(csv);
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      fontSize: 12,
      marginTop: theme.spacing(1),
      minWidth: 180,
      fontFamily: 'Nunito',
      color: 'rgb(55, 65, 81)',
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        fontSize: 14,
        '& .MuiSvgIcon-root': {
          fontSize: 12,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {},
      },
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[300],
      }),
    },
  }));

  // @ts-ignore
  // @ts-ignore
  return (
    <ThemeProvider theme={tableTheme}>
      {advancedTable && (
        <Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              backgroundColor: '#ffffff',
              borderRadius: '4px',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'space-between',
              padding: '10px 10px',
              '@media max-width: 768px': {
                flexDirection: 'column',
              },
            })}
          >
            <Box>
              <div className="flex flex-row items-center gap-2 py-2">
                <Button
                  variant="contained"
                  size="small"
                  id="basic-button"
                  startIcon={<Download />}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  sx={{
                    color: '#fff',
                    textTransform: 'none',
                  }}
                  className="font-nunito text-sm"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  Export data
                </Button>
                <StyledMenu
                  anchorEl={anchorEl}
                  open={open}
                  className="font-nunito text-sm"
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem className="font-nunito" onClick={handleExportData}>
                    Export CSV (all Data)
                  </MenuItem>
                  <MenuItem
                    disabled={
                      !table.getIsSomeRowsSelected() &&
                      !table.getIsAllRowsSelected()
                    }
                    onClick={() =>
                      handleExportRows(table.getSelectedRowModel().rows)
                    }
                  >
                    Export CSV (selected rows)
                  </MenuItem>
                </StyledMenu>
              </div>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
              <MRT_GlobalFilterTextField
                sx={{ marginRight: 0 }}
                table={table}
              />
              {/* add custom button to print table  */}
              <IconButton
                onClick={() => {
                  window.print();
                }}
              >
                <PrintOutlined />
              </IconButton>
              <MRT_ToolbarInternalButtons table={table} />
            </Box>
          </Box>
        </Box>
      )}
      {/*      {columnFilterOpen && (
        <Stack direction={'column'} className="bg-white" gap="10px">
          <Paper>
            <Grid container spacing={2} columnSpacing={2}>
              {table.getLeafHeaders().map(
                (header: any) =>
                  header.column.getCanFilter() && (
                    <Grid item xs={4} md={2} spacing={5}>
                      <MRT_TableHeadCellFilterContainer
                        key={header.id}
                        header={header}
                        table={table}
                        in
                      />
                    </Grid>
                  )
              )}
            </Grid>
          </Paper>
        </Stack>
      )}*/}
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
}

export default Table;
