import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  FBD_Keygen_BANNED,
  MakeFBDocType,
} from '@rabbit/firebase/doctype';
import { NoSqlDoc } from '@rabbit/firebase/doctype';
import { ConsumerLink } from '../persona/consumer';
import { ManufacturerLink } from '../persona/manufacturer';
import { VendableLink } from './vendable';
import { PersonaLink, RetailerLink } from '../persona';
import {
  DateTime,
  ImageUrls,
  Money,
  UserUploadedDocument,
  Z_UserUploadedDocument,
} from './../base/basic';
import { DTCategoryInfo, Z_DTCategoryInfo } from './category';
import { SRVInfo, VehicleInfo, Z_SRVInfo, Z_VehicleInfo } from './holding-info';
import { z } from 'zod';
import { OurDateTime } from '@rabbit/utils/ts';

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                Subset Types                                */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

export type HoldingLink = string;
export const Z_HoldingLink = z.string();

/* -------------------------------------------------------------------------- */
/*                              Self Registration                             */
/* -------------------------------------------------------------------------- */

/** Structure for self-registration of an item that is not in the database. The data is a subset of Vendable. */
export type SelfRegistration = {
  /** Human readable descriptive short headline title of the vendable. May be shown on its own. */
  title: string;

  /** Url of image. If array then it is multiple images with the first being the headline. */
  img?: ImageUrls;

  /**
   * Full image data objects, including metadata, storage reference, etc.
   */
  images?: UserUploadedDocument[];
  /** UPC Barcode number
   *
   * As defined by the International Article Numbering Association (EAN.UCC)
   * https://en.wikipedia.org/wiki/Universal_Product_Code
   * */
  upc?: string;

  /** Brand name as supplied by and displayed to the consumer */
  brand: string;

  /** Category name, as supplied by and displayed to the consumer. Might be mapped to category below in the future */
  category_title?: string;

  /** Category info, using hardcoded system for now */
  category?: DTCategoryInfo;

  /** ManufacturerLink:: Manufacturer - their persona */
  mfr?: ManufacturerLink;

  /** Manufacturer Part Number */
  mpn?: string;

  /** If the missing info CTA on the holding details page should be shown */
  show_missing_info_cta?: boolean;

  /** @deprecated use srvInfo instead */
  vehicleInfo?: VehicleInfo;

  srvInfo?: SRVInfo;
};

export const Z_SelfRegistration = z
  .object({
    title: z.string(),
    img: z.array(z.string()).optional(),
    images: z.array(Z_UserUploadedDocument).optional(),
    upc: z.string().optional(),
    brand: z.string(),
    category_title: z.string().optional(),
    category: Z_DTCategoryInfo.optional(),
    mfr: z.string().optional(),
    mpn: z.string().optional(),
    show_missing_info_cta: z.boolean().optional(),
    vehicleInfo: Z_VehicleInfo.optional(),
    srvInfo: Z_SRVInfo.optional(),
  })
  .nullable();

/* -------------------------------------------------------------------------- */
/*                              Shoplifter types                              */
/* -------------------------------------------------------------------------- */

export interface ShopifyLinks {
  orderId: number;
  itemId: number;
  customerId: number;
  identicalProductIndex: number;
  shopId: number;
}

export const Z_ShopifyLinks = z.object({
  orderId: z.number(),
  itemId: z.number(),
  /** When ordering the same product multiple times, the information above
   * will always be identical for each product. The only information we have is the quantity.
   * Eg: we buy three ovens with the same sku.
   * They need to trigger the generation of three separate holdings
   * identicalProductIndex is assigned from 1 to x where x is the quantity purchased */
  identicalProductIndex: z.number(),
  customerId: z.number(),
  shopId: z.number(),
});

/* -------------------------------------------------------------------------- */
/*                       Old Warranty types (deprecated)                      */
/* -------------------------------------------------------------------------- */
// Will be left here as a reference until we finish moving to the new system
// For the new model, see libs/data/types/src/lib/warranty.ts

export interface WarrantyDuration {
  amount: number;
  division: 'years' | 'months' | 'days';
}

export const Z_WarrantyDuration = z.object({
  amount: z.number(),
  division: z.union([
    z.literal('years'),
    z.literal('months'),
    z.literal('days'),
  ]),
});

/* -------------------------------------------------------------------------- */
/*                             Other subset types                             */
/* -------------------------------------------------------------------------- */

// Holding Manufacturer - Questionnaire responses
export type ProductQuestionnaireResponses = {
  purchase_inspiration?: string;
  product_to_be_used?: string;
  product_to_be_used_other?: string;
  purchase_reason?: string;
  purchase_reason_other?: string;
  product_main_use?: string;
  product_main_use_other?: string;
  property_shade?: string;
  shelta_aware?: string;
  purchase_satisfaction?: string;
  skip?: string;
};

export type HoldingExtraService = {
  label: string;
  price: Money;
  purchase_location?: RetailerLink | ManufacturerLink | null; // Other persona links might be added in the future - DC
};

export type InstallationData = {
  installation_date?: DateTime | null;
  installation_time?: DateTime | null;
  installer?: any;
};

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                               Document Types                               */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                   Holding                                  */
/* -------------------------------------------------------------------------- */

/** DTHolding_Public contains all details that may be shared with the manufacturer or any repairer.
 */
export interface DTHolding_Public extends NoSqlDoc {
  /** ConsumerLink:: The consumer that purchased the holding */
  consumer: ConsumerLink;

  /** The moment that the item was purchased. */
  purchase_time: DateTime;

  /** The vendable identified in the Vendadex. If not identified, self registration will be shared. */
  vendable: VendableLink;

  /** User's self registration data, for sharing when  */
  self_registration?: SelfRegistration;

  /** An array of open cases represented here by their ids */
  active_cases?: string[];

  /** An array of closed cases represented here by their ids */
  closed_cases?: string[];

  /** Serial number */
  serial?: string;

  /** If the user has deleted this holding. Document not actually removed for now as a safeguard, but this practice needs to be evaluated in regards to GDPR -dc */
  deleted: boolean;

  // redundant, because could be taken from vendable, but makes authorization easier..
  mfr: ManufacturerLink;
}

export const FBD_Holding_Public = MakeFBDocType<DTHolding_Public>({
  name: 'Holding_Public',
  collection: 'holding_public',
  empty: () => {
    const result: DTHolding_Public = {
      ...Empty_NoSqlDoc(),
      consumer: '',
      purchase_time: 0,
      vendable: '',
      deleted: false,
      mfr: '',
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to that of the related private document.'
  ),
});

/* -------------------------------------------------------------------------- */
/*                               Private Holding                              */
/* -------------------------------------------------------------------------- */
export interface DTHolding_Private extends NoSqlDoc {
  /** ConsumerLink:: The consumer that purchased the holding */
  consumer: ConsumerLink;

  /** The moment that the holding was created. */
  created_time: DateTime;

  /** How much they paid for it */
  purchase_price?: Money;

  /** Where they purchased it */
  purchase_location?: RetailerLink | null;

  /** Where they purchased it, as inputted by the user, if the retailer was not listed in the database */
  purchase_location_other?: string | null;

  /* Country where holding was purchased */
  purchase_country?: string;

  /** The vendable identified in the Vendadex. If not identified, self registration will be shared. */
  vendable: VendableLink;

  /** Serial number */
  serial?: string;

  /** Photographic evidence of the holding's serial number */
  serial_proof?: UserUploadedDocument[];

  /** Receipt from the purchase */
  receipt?: UserUploadedDocument[]; // TODO: There can be multiple holdings from a receipt so we need to consider if we are tracking receipts as a separate object.

  // redundant, because could be taken from vendable, but makes authorization easier..
  mfr: ManufacturerLink;

  /** Additional services sold for the given holding that are unrelated to warranties, e.g. sealing in NuCover  */
  extraServices?: HoldingExtraService[];

  /** Links to the Shoplifter order from which this holding originated, if applicable */
  shopifyLinks?: ShopifyLinks;

  /** Installation details*/
  installation?: InstallationData;

  installer?: string;

  installation_attachments?: UserUploadedDocument[];

  internal_comment?: string;

  chassis_number?: string;

  registration_number?: string;

  production_date?: DateTime;

  mileage?: any;

  // Maybe things like user's own notes will go here.
}

export const FBD_Holding_Private = MakeFBDocType<DTHolding_Private>({
  name: 'Holding_Private',
  collection: 'holding_private',
  empty: () => {
    const result: DTHolding_Private = {
      ...Empty_NoSqlDoc(),
      created_time: OurDateTime.nowUTCTimestamp(),
      consumer: '',
      vendable: '',
      mfr: '',
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});

/* -------------------------------------------------------------------------- */
/*                            Manufacturer Holding                            */
/* -------------------------------------------------------------------------- */

/** DTHolding_Manufacturer is analogous to a manufacturer registration of a product. */
export interface DTHolding_Manufacturer extends NoSqlDoc {
  // The registration element ==============================
  /** ConsumerLink:: The consumer that purchased the holding */
  consumer: ConsumerLink;

  /** The moment that the registration took place. */
  register_time: DateTime;

  /** Serial number to identify the warranty registration */
  warranty_id?: string;

  /** Proof of purchase */
  purchase_proof?: UserUploadedDocument[];

  // Demographics ========================================

  /** User's gender */
  gender?: string;

  /** Questionnaire entries that manufacturers may ask for */
  questionnaire?: ProductQuestionnaireResponses;

  // Transferred from DTHolding_Private ==============================

  /** How much they paid for it */
  purchase_price?: Money;

  /** Where they purchased it */
  purchase_location?: RetailerLink | null;

  /** Where they purchased it, as inputted by the user, if the retailer was not listed in the database */
  purchase_location_other?: string | null;

  /* Country where holding was purchased */
  purchase_country?: string;

  /** Serial number */
  serial?: string;

  /** Photographic evidence of the holding's serial number */
  serial_proof?: UserUploadedDocument[];

  installation_attachments?: UserUploadedDocument[];

  /** The moment that the item was purchased. */
  purchase_time?: DateTime;

  /** The vendable identified in the Vendadex. If not identified, self registration will be shared. */
  vendable: VendableLink;

  /** ManufacturerLink:: Manufacturer - their persona */
  mfr?: ManufacturerLink;

  /** Tenant principals */
  principals?: PersonaLink[];

  // Maybe the manufacturer will want to add their own notes here. Or maybe that's a separate object.

  /** Links to the Shoplifter order from which this holding originated, if applicable */
  shopifyLinks?: ShopifyLinks;
}

export const FBD_Holding_Manufacturer = MakeFBDocType<DTHolding_Manufacturer>({
  name: 'Holding_Manufacturer',
  collection: 'holding_manufacturer',
  empty: () => {
    const result: DTHolding_Manufacturer = {
      ...Empty_NoSqlDoc(),
      consumer: '',
      register_time: OurDateTime.nowUTCTimestamp(),
      vendable: '',
    };
    return result;
  },
  keygen: FBD_Keygen_BANNED(
    'docid should be set to that of the related private document.'
  ),
});
