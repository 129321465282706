import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { UserUploadedDocument } from '../base/basic';


// --- ENUMS ---

// High level type of note
export enum NoteType {
  GENERAL = 'general',
  INTERACTION = 'interaction',
  // Future types can be added here
}

// Note Status - For tracking note lifecycle
export enum NoteStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DELETED = 'deleted'
}

// Note Entity Type - What the note is attached to
export enum NoteEntityType {
  HOLDING = 'holding',
  WARRANTY = 'warranty',
  CLAIM = 'claim',
  // TODO: add other types as needed
}

export enum CallOutcome {
  CONNECTED = 'connected',
  VOICEMAIL = 'voicemail',
  NO_ANSWER = 'no-answer',
  BUSY = 'busy'
}

export enum CommunicationDirection {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound'
}

export enum InteractionChannel {
  PHONE = 'phone',
  EMAIL = 'email'
}

// Who the interaction was with
export enum InteractionParty {
  DEALER = 'dealer',
  CONSUMER = 'consumer',
  INTERNAL = 'internal',
  // REPAIRER = 'repairer',
  // WARRANTOR = 'warrantor',
  // EXTERNAL_REPAIRER = 'external-repairer',
  OTHER = 'other'
}

// Priority - For sorting notes
export enum Priority {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  STANDARD = 'standard'
}


// Predefined categories for better organization
export enum NoteCategoryType {
  GENERAL = 'general',
  SUPPORT = 'support',
  SALES = 'sales',
  TECHNICAL = 'technical',
  BILLING = 'billing',
  WARRANTY = 'warranty',
  COMPLAINT = 'complaint',
  FEEDBACK = 'feedback',
  OTHER = 'other'
}


export interface InteractionMetadata {
  channel: InteractionChannel;
  party: InteractionParty;
  direction: CommunicationDirection;
  // Common fields
  contactInfo?: string;
  followUpRequired?: boolean;
  followUpDate?: number;
  // Channel-specific fields
  duration?: number;        // For phone calls
  phoneNumber?: string;     // For phone calls
  recipients?: string[];    // For emails
}

// --- METADATA INTERFACES ---

// Base metadata interface
export interface NoteMetadataBase {
  category: NoteCategoryType;
}

// Call log specific metadata
export interface CallLogMetadata extends NoteMetadataBase {
  duration: number;         // in seconds
  phoneNumber: string;
  callDirection: CommunicationDirection;
  callOutcome: CallOutcome;
}

// Email log specific metadata
export interface EmailLogMetadata extends NoteMetadataBase {
  recipients: string[];
  emailDirection: CommunicationDirection;
}

// Customer interaction metadata
export interface CustomerInteractionMetadata extends NoteMetadataBase {
  interactionChannel: InteractionChannel;
  contactInfo: string;
  followUpRequired: boolean;
  followUpDate?: number;
}

// --- MAIN NOTE INTERFACE ---

// The main note interface
export interface DTNote extends NoSqlDoc {
  // Note Type
  noteType: NoteType;

  // Basic Fields
  title: string;
  content: string;
  
  // Classification
  category: NoteCategoryType;
  status: NoteStatus;
  
  // Author Information
  updatedBy: string;          // User ID who created the note
  // authorPersonaType: string; // Type of user (warrantor, consumer, etc.)
  
  // Entity References
  entityType: NoteEntityType;
  entityId: string;          // ID of the entity this note is attached to
  
  // Organizational
  tags?: string[];            // For flexible categorization
  tagIds?: string[];          // For future tag implementation
  // priority?: Priority;
  
  // Metadata - using discriminated union
  metadata?: InteractionMetadata;
  
  // Attachments
  attachments?: UserUploadedDocument[];
  
  // Access Control
  tenantLink: string;        // For tenant-level access control
  
  // Version Control
  version: number;           // For tracking edits
}

export const FBD_Note = MakeFBDocType<DTNote>({
  name: 'note',
  collection: 'note',
  empty: () => {
    const result: DTNote = {
      ...Empty_NoSqlDoc(),
      noteType: NoteType.GENERAL,
      title: '',
      content: '',
      category: NoteCategoryType.GENERAL,
      status: NoteStatus.ACTIVE,
      updatedBy: '',
      // authorPersonaType: '',
      entityType: NoteEntityType.HOLDING,
      entityId: '',
      tags: [],
      tagIds: [],
      // priority: Priority.STANDARD,
      metadata: undefined,
      attachments: [],
      tenantLink: '',
      version: 1,
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});
