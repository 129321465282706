import { Fragment, useContext, useEffect, useState } from 'react';
import Select, { components, MenuProps } from 'react-select';
import { useField, useFormikContext } from 'formik';
import { handleKeyPress } from '@rabbit/elements/shared-components';
import { FacetFilter, SearchVendables } from '@rabbit/search/cherchons';
import { InputTypeSelectSettingsShape } from '@rabbit/elements/shared-types';
import { useDebounce } from '../../../utils/hooks/useDebounce';
import { generateSelectStyles } from '../../../utils/consts';
import { AppContext } from '@rabbit/app-context';
import Bus from '@rabbit/bus';

export interface InputAutoCompleteVendableProps {
  name: string;
  settings: InputTypeSelectSettingsShape;
  onChangeFromParent?: (...args: any) => any;
  partsOnly?: boolean;
}

interface OptionShape {
  id: string;
  label: string;
  value: string;
}

export function InputAutoCompleteVendable({
  name,
  settings,
  onChangeFromParent,
  partsOnly = false,
}: InputAutoCompleteVendableProps) {
  const { setFieldValue } = useFormikContext();
  const {
    placeholder,
    isMulti = false,
    isClearable = true,
    disabled = false,
    tenantLink = '',
    customButton,
    value,
    ...props
  } = settings;
  const [field, meta] = useField(name);

  const [options, setOptions] = useState<OptionShape[]>([]);
  const [inputText, setInputText] = useState('');
  const debouncedInput = useDebounce(inputText, 500);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const { config } = useContext(AppContext);
  const isUniqueProductActivated =
    config.HOLDINGS.NEW_REGISTRATION_FLOW.UNIQUE_PRODUCT;

  let errorField = false;
  if (meta?.error && meta?.touched) errorField = true;

  function onChange(option: any) {
    if (isMulti) {
      void setFieldValue(
        name,
        option ? option.map((item: { value: any }) => item.value) : []
      );
    } else {
      void setFieldValue(name, option?.value ? option.value : '');
    }
    if (onChangeFromParent) onChangeFromParent(option);
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option: { value: any }) => field.value.indexOf(option.value) >= 0
          )
        : options.find(
            (option: { value: any }) => option.value === field.value
          );
    } else {
      return isMulti ? [] : '';
    }
  };

  useEffect(() => {
    if (meta?.initialValue) {
      setInputText(meta.initialValue);
    }
  }, [meta?.initialValue]);

  useEffect(() => {
    const fetchData = async () => {
      const facetFilters: FacetFilter[] = [
        {
          name: 'mfr',
          value: `M:${tenantLink}`,
        },
      ];

      if (partsOnly) {
        facetFilters.push({
          name: 'isPart',
          value: true,
        });
      }

      // TODO : Need to Add an additional condition to retrieve the product when "unique" is set to false (Only For ABBY).
      // if(isUniqueProductActivated){
      // facetFilters.push({
      //   name: 'unique',
      //   value: false,
      // });
      // }

      const data = await SearchVendables(debouncedInput, {
        hitsPerPage: 500,
        page: 0,
        facetFilters,
      });
      if (data?.items.length) {
        const newOptions: OptionShape[] = [];
        data.items.map((item) => {
          const shouldAddItem =
            !isUniqueProductActivated || (isUniqueProductActivated && !item?.unique);

          if (shouldAddItem) {
            newOptions.push({
              id: item.docid,
              label: config.VENDABLES.SKU_SEARCH
                ? `${item.mpn} (${item.title})`
                : `${item.title} (${item.mpn})`,
              value: item.docid,
            });
          }
        });
        setOptions(newOptions);
      }
      setIsLoadingResults(false);
    };

    if (disabled && value) {
      setOptions([value]);
      setFieldValue(name, value.id);
    } else {
      if (debouncedInput.length) {
        setIsLoadingResults(true);
        void fetchData();
      } else setOptions([]);
    }
  }, [debouncedInput, tenantLink, partsOnly, disabled, value]);

  const onInputChange = (e: string) => {
    setInputText(e);
  };

  const CustomMenu = (props: MenuProps<any>) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div className="font-nunito text-base font-semibold text-gray-900">
            Results
          </div>
          {props.children}
          {customButton && (
            <div className="mt-2 w-full border-t border-gray-300 pt-2">
              {customButton}
            </div>
          )}
        </components.Menu>
      </Fragment>
    );
  };

  return (
    <Select
      {...field}
      {...props}
      options={options}
      isMulti={isMulti}
      classNames={generateSelectStyles(errorField)}
      //styles={errorField ? errorCustomStyles : customStyles}
      placeholder={placeholder}
      defaultValue={field.value}
      components={
        // settings.dropDown === 'disabled'
        //   ? {
        //       DropdownIndicator: () => null,
        //       IndicatorSeparator: () => null
        //     }
        //   : undefined
        customButton
          ? {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              Menu: CustomMenu,
            }
          : {}
      }
      value={
        options
          ? isMulti
            ? options.find(
                (option: { value: any }) => option.value === field.value
              )
            : getValue()
          : ''
      }
      onKeyDown={handleKeyPress}
      onChange={onChange}
      onInputChange={onInputChange}
      isClearable={isClearable}
      isDisabled={disabled}
      noOptionsMessage={({ inputValue }) =>
        !inputValue
          ? 'Type something...'
          : inputText === debouncedInput && !isLoadingResults
          ? 'No results found'
          : 'Loading...'
      }
    />
  );
}

export default InputAutoCompleteVendable;
