 import {
  Empty_NoSqlDoc,
  FBDTKeygenGeneric,
  MakeFBDocType,
  NoSqlDoc,
} from '@rabbit/firebase/doctype';
import { DTNote, NoteStatus } from './note';
import { TenantLink } from './tenant';

export interface DTNote_History extends NoSqlDoc {
  /** The current version number */
  version: number;
  /** Unique identifier for the note document */  
  noteLink: string;
  /** status of the note */
  status: NoteStatus;

  /** Timestamp of when the note was updated */
  tupdate: number;
  /** Snapshot of the note at this version */
  snapshot: DTNote[];
  /** The tenant who owns this note */
  tenantLink: TenantLink;

}

export const FBD_Note_History = MakeFBDocType<DTNote_History>({
  name: 'Note History',
  collection: 'note_history',
  empty: () => {
    const result: DTNote_History = {
    ...Empty_NoSqlDoc(), 
      version: 1, 
      noteLink: '', 
      status: NoteStatus.ACTIVE,
      tupdate: new Date().getTime(), 
      snapshot: [], 
      tenantLink: '' 
    };
    return result;
  },
  keygen: FBDTKeygenGeneric,
});